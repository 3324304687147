.hire-card {
  max-width: 1280px;
  margin: -200px auto 0; /* Negative margin to pull it up */
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  padding: 40px;
  position: relative; /* For proper stacking */
  z-index: 10; /* Ensure it stays above hero elements */
}

.hire-card-header {
  text-align: center;
  margin-bottom: 48px;
}

.hire-card-header h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #07143f;
}

.hire-card-header p {
  font-size: 16px;
  color: #07143f;
  max-width: 800px;
  margin: 0 auto;
  line-height: 35px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin-bottom: 32px;
}

.feature-card {
  text-align: center;
  padding: 24px;
}

.feature-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.feature-icon {
  width: 32px;
  height: 32px;
  color: #2563eb;
}

.feature-card h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #1a1a1a;
}

.feature-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
}

.cta-button-wrapper {
  text-align: center;
  margin-top: 32px;
}

.cta-button {
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 9999px;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #1d4ed8;
}

@media (max-width: 1824px) {
  .hire-card {
    max-width: 1280px;
    margin: -70px auto 0;
  }
}

@media (max-width: 1257px) {
  .hire-card {
    max-width: 1280px;
    margin: -180px auto 0;
  }
}
@media (max-width: 992px) {
  .hire-card {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    border: none;
    box-shadow: none;
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }

  .hire-card {
    margin-top: 10px; /* Less overlap on mobile */
    margin-left: 16px;
    margin-right: 16px;
    padding: 24px;
  }
}
@media (max-width: 591px) {
  .hire-card {
    max-width: 1280px;
    margin: 10px;
  }
}
