.featured-work {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.featured-work-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  margin: 80px auto 0;
  background-color: white;
  border-radius: 20px;
  padding: 0 40px;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
}

.featured-work-header.mobile {
  padding: 0 20px;
  margin: 40px auto 0;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.featured-work-header h2 {
  font-size: 32px;
  font-weight: 600;
  color: #1a1a1a;
}

.featured-work-header.mobile h2 {
  font-size: 24px;
}

.projects-container {
  width: 100%;
  height: 636px;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  margin-top: 40px;
  box-sizing: border-box;
}

.projects-container.mobile {
  height: 400px;
  margin-top: 20px;
}

.projects-container.tablet {
  height: 520px;
  margin-top: 30px;
}

.projects-track {
  display: flex;
  gap: 24px;
  padding: 0 40px;
  position: absolute;
  top: 0;
  left: 0;
  height: 636px;
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
  box-sizing: border-box;
}

.projects-track.loaded {
  opacity: 1;
}

.projects-track.mobile {
  padding: 0 20px;
  height: 400px;
  gap: 20px;
}

.projects-track.tablet {
  padding: 0 24px;
  height: 520px;
  gap: 20px;
}

.project-card {
  width: 499px;
  height: 636px;
  flex-shrink: 0;
  border-radius: 16px;
  overflow: hidden;
  background: transparent;
  cursor: pointer;
  position: relative;
  margin: 0;
  box-sizing: border-box;
}

.project-card .navigationArrow {
  position: absolute;
  bottom: 0;
  z-index: 10;
  right: 20px;
  bottom: 70px;
  width: 40px;
}
.project-card.mobile {
  width: 300px;
  height: 400px;
  border-radius: 12px;
}

.project-card.tablet {
  width: 380px;
  height: 520px;
  border-radius: 14px;
}

.project-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.year-tag {
  position: absolute;
  top: 16px;
  right: 10px;
  background-color: #3df3fc;
  color: #0c4a6e;
  padding: 4px 12px;
  border-radius: 999px;
  font-size: 11px;
  font-weight: 500;
}

.project-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 20px;
}

.project-til {
  color: #fff;
  background: linear-gradient(
    94.3deg,
    rgba(0, 0, 0, 0.4) 2.39%,
    rgba(0, 0, 0, 0.1) 98.79%
  );
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 23px;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
}

.project-til.mobile {
  font-size: 18px;
  padding: 4px 10px;
}

.tag {
  background: linear-gradient(
    94.3deg,
    rgba(0, 0, 0, 0.4) 2.39%,
    rgba(0, 0, 0, 0.1) 98.79%
  );
  color: #fff;
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 14px;
}

.tag.mobile {
  font-size: 12px;
  padding: 4px 8px;
}

@media screen and (max-width: 480px) {
  .featured-work-header {
    padding: 0 16px;
    margin: 30px auto 0;
  }

  .featured-work-header h2 {
    font-size: 20px;
  }

  .projects-container {
    height: 320px;
    margin-top: 16px;
  }

  .projects-track {
    padding: 0 16px;
    height: 320px;
    gap: 16px;
  }

  .project-card {
    width: 250px;
    height: 320px;
    border-radius: 10px;
  }

  .year-tag {
    top: 10px;
    right: 8px;
    padding: 3px 8px;
    font-size: 12px;
  }

  .project-info {
    bottom: 12px;
    left: 12px;
    right: 12px;
  }

  .project-til {
    font-size: 16px;
    padding: 4px 8px;
  }

  .tags {
    margin-top: 10px;
    gap: 6px;
  }

  .tag {
    font-size: 10px;
    padding: 3px 6px;
    border-radius: 8px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .featured-work-header {
    margin: 40px auto 0;
  }

  .projects-container {
    height: 400px;
  }

  .projects-track {
    height: 400px;
  }

  .project-card {
    width: 300px;
    height: 400px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .featured-work-header {
    margin: 60px auto 0;
    padding: 0 30px;
  }

  .featured-work-header h2 {
    font-size: 28px;
  }

  .projects-container {
    height: 520px;
    margin-top: 30px;
  }

  .projects-track {
    padding: 0 24px;
    height: 520px;
    gap: 20px;
  }

  .project-card {
    width: 380px;
    height: 520px;
  }
}

@media screen and (min-width: 993px) {
  .featured-work-header {
    margin: 80px auto 0;
  }

  .projects-container {
    height: 636px;
    margin-top: 40px;
  }

  .projects-track {
    height: 636px;
  }

  .project-card {
    width: 499px;
    height: 636px;
  }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
  .featured-work-header {
    margin: 20px auto 0;
  }

  .projects-container {
    height: 280px;
    margin-top: 16px;
  }

  .projects-track {
    height: 280px;
  }

  .project-card {
    width: 240px;
    height: 280px;
  }

  .project-til {
    font-size: 16px;
    padding: 4px 8px;
  }

  .tags {
    margin-top: 8px;
  }

  .tag {
    font-size: 10px;
    padding: 3px 6px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .projects-track {
    max-width: 100%;
  }
}
