.product-section {
  height: 700px;
  background-color: #fff;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 50px;

  max-width: 1280px;
  margin: 0 auto 0;
  h2 {
    font-size: 34px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #07143f;
    line-height: 50px;

    // margin-top: 50px;
  }
  img {
    width: 200px;
  }
  .brandImg1 {
    position: absolute;
    left: 0;
    top: 0;
  }

  .brandImg2 {
    position: absolute;
    left: 45%;
    top: 80px;
    // transform: translateX(50px);
  }

  .brandImg3 {
    position: absolute;
    right: 0;
    top: 0;
  }

  .brandImg4 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .brandImg5 {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 800px) {
  .product-section {
    // height: 600px;
    .brandImg2 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) !important;
      top: 154px;
      // transform: translateX(50px);
    }
    .brandImg4 {
      bottom: 60px;
    }

    .brandImg5 {
      bottom: 60px;
    }
    h2 {
      font-size: 29px;
      margin-bottom: 0px;
    }
  }
}
