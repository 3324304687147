.footer {
  background-color: #fff;
  padding: 2rem 6rem;
}

.newsletter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.newsletter-title {
  font-size: 30px;
  font-weight: 500;
  color: #07143f;
  margin-bottom: 0.5rem;
}

.newsletter-subtitle {
  font-size: 16px;
  color: #07143f;
  /* margin-bottom: 1rem; */
}

.email-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  border: 1px solid #07143f;
  border-radius: 2rem;
  padding: 0.25rem;
  background-color: #fff;
  margin-top: 30px;
}

.email-input {
  flex: 1;
  border: none;
  padding: 0.75rem 1.25rem;
  outline: none;
  font-size: 12px;
  color: #07143f;
  background: transparent;
}

.subscribe-button {
  padding: 0.75rem 1.5rem;
  background-color: #07143f;
  color: white;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.2s;
}

.subscribe-button:hover {
  background-color: #07143f;
}

.footer-nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.footer-nav-link {
  color: #6a728c;
  text-decoration: none;
  font-size: 11px;
  transition: color 0.2s;
  cursor: pointer;
}

.footer-link_active {
  color: #2563eb;
  text-decoration: underline;
}

.footer-nav-link:hover {
  color: #2563eb;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.social-link {
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #6a728c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6a728c;
  transition: all 0.2s;
}

.social-link:hover {
  background-color: #f8fafc;
  color: #2563eb;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.copyright {
  color: #6a728c;
  font-size: 12px;
}

.legal-links {
  display: flex;
  gap: 2rem;
}

.legal-link {
  color: #64748b;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.2s;
}

.legal-link:hover {
  color: #2563eb;
}

/* Banner Section */
.hire-banner {
  background-color: #07143f;
  border-radius: 1rem;
  padding: 3rem;
  padding-bottom: 0px;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 327px;

  /*  */
  max-width: 1280px;
  margin: -200px auto 0; /* Negative margin to pull it up */
  /* background-color: white; */
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  padding: 40px;
  position: relative; /* For proper stacking */
  z-index: 10; /* Ensure it stays above hero elements */
  margin-bottom: 40px;
  padding-bottom: 0px;
}

.banner-content {
  z-index: 2;
}

.banner-title {
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.book-call-btn {
  background-color: white;
  color: #0f172a;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.book-call-btn:hover {
  background-color: #f8fafc;
  transform: translateY(-2px);
}

.banner-illustration {
  /* position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

/* Existing Footer Styles */
.footer {
  background-color: #fff;
  padding: 2rem 6rem;
}

.newsletter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

@media (max-width: 1065px) {
  .hire-banner {
    padding: 2rem;
    flex-direction: column;
    text-align: center;
  }

  .banner-title {
    font-size: 2rem;
  }

  .banner-illustration {
    position: relative;
    width: 100%;
    margin-top: 2rem;
    transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 15px;
  }

  .footer-nav {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .legal-links {
    gap: 1rem;
  }
}
