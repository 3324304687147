@font-face {
  font-family: generalFont;
  src: url(./assets/fonts/ABeeZee/ABeeZee-Regular.ttf);
}

@font-face {
  font-family: BoldFont;
  src: url(./assets/fonts/nexa/Nexa-Bold.otf);
}

:root {
  --navbar-height: 80px;
  --OpenSans-Bold: BoldFont;
  --ABeeZee-Regular: generalFont;
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  /* background-color: red; */
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: var(--ABeeZee-Regular) !important;
  /* color: #07143f; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--OpenSans-Bold);
}

::-webkit-scrollbar {
  width: 0%;
  height: 4px;
  background-color: transparent !important;
  scroll-behavior: smooth;
  z-index: 10;
  position: relative;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border-right: 3px rgba(0, 0, 0, 0) solid;
  -webkit-box-shadow: inset 0 0 6px #f1f0fd;
  background-color: rgba(0, 0, 0, 0.4);
  scroll-behavior: smooth;
  background-clip: padding-box;
}
