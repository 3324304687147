.mobile_how_it_works_outside_body {
  display: none;
}

@media (max-width: 992px) {
  .mobile_how_it_works_outside_body {
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin-top: 40px;
    padding: 20px;
  }

  .mobile_how_it_works_body {
    background: #f3f5ff;
    width: 100%;
    min-height: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    position: relative;
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 40px;
  }

  .mobile_how_it_works_body_index {
    background: #ffffff;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
  }

  .mobile_how_it_works_body_icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile_how_it_works_body_description {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 26px;
  }
}
