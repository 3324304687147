.testimonials-section {
  padding: 6rem 4rem;
  background-color: #4a72ff;
  color: white;
  min-height: 100vh;
  min-height: clamp(600px, 100vh - var(--navbar-height), 800px);

  overflow: hidden;
}

.content-container {
  display: flex;
  gap: 4rem;
  max-width: 1400px;
  margin: 0 auto;
}

.left-content {
  flex: 1;
}

.header h2 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
  color: white;
}

.header p {
  font-size: 1.2rem;
  opacity: 0.9;
  color: white;
}

.stats-container {
  display: flex;
  gap: 4rem;
  margin-top: 20px;
}

.stat-item h3 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: white;
}

.stat-item p {
  font-size: 1.1rem;
  opacity: 0.9;
  color: white;
}

.map-container {
  flex: 1;
  position: relative;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.dotted-map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.user-avatar {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Testimonials Slider Styles */
.testimonials-slider-container {
  position: relative;
  max-width: 1400px;
  margin: 4rem auto 0;
  padding: 0 60px;
  margin-top: 12px;
  /* overflow: hidden; */
  /* padding-bottom: 20px; */
}

.testimonials-slider {
  overflow: visible;
}

.testimonial-card {
  background-color: white;
  color: #333;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
  max-height: 310px;
  min-height: 310px;
  overflow-y: scroll;
  position: relative;
}

.testimonial-card .reason {
  font-size: 12.5px;
  color: #07143f;
}

.stars {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 15px;
  margin-top: 20px;
}

.content {
  font-size: 13px;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #07143f;
  line-height: 25px;
}
/* .author {
  position: absolute;
  bottom: 0;
} */
.author h4 {
  font-size: 1.1rem;
  font-weight: 7600;
  margin-bottom: 0.25rem;
  color: #07143f;
}

.author p {
  font-size: 12px;
  /* font-style: italic; */
  color: #666666;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: transform 0.2s ease;
}

.slider-arrow:hover {
  transform: translateY(-50%) scale(1.1);
}

.slider-arrow.prev {
  left: 0;
}

.slider-arrow.next {
  right: 0;
}

.slider-arrow svg {
  color: #4a72ff;
}

@media (max-width: 1200px) {
  .content-container {
    flex-direction: column;
  }

  .header h2 {
    font-size: 2.5rem;
  }

  .testimonials-section {
    padding: 3rem 2rem;
  }

  .testimonials-slider-container {
    padding: 0 40px;
  }
}

@media (max-width: 768px) {
  .testimonials-section {
    /* margin-top: -40px; */
    position: relative;
    top: -20px;
  }
  .stats-container {
    gap: 2rem;
  }
  .stat-item h3 {
    font-size: 32px;
  }

  .stat-item p {
    font-size: 15px;
  }
  .header p {
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .slider-arrow {
    width: 40px;
    height: 40px;
  }

  .map-container {
    display: none;
  }

  .testimonials-slider-container {
    margin-top: 50px;
    padding: 0;
  }
  .slider-arrow {
    display: none;
  }
}
