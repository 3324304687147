.faq-container {
  background-color: #f3f5ff;
  display: flex;
  max-width: 1280px;
  margin: 0px auto 0;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.faq-header {
  /* margin-bottom: 48px; */
  margin-right: 30px;
}

.faq-header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 12px;
}

.faq-header p {
  color: #07143f;
  font-size: 1rem;
  margin-bottom: 24px;
}

.ask-question-btn {
  background-color: #4263eb;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.ask-question-btn:hover {
  background-color: #3651c7;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.faq-item {
  border-radius: 5px;
  background: #f8f9fa;
  /* border: 1px solid #e5e7eb41; */
  overflow: hidden;
  filter: drop-shadow(5px 5px 5px rgba(128, 128, 128, 0.153));
}

.faq-question {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  transition: background-color 0.15s ease;
}

.toggle-container {
  width: 50px;
  height: 50px;
  background-color: #f3f5ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-icon {
  flex-shrink: 0;
  color: #07143f;
}

.faq-answer {
  div {
    overflow: hidden;
    padding: 0 24px 24px;
    color: #07143f;
    line-height: 1.5;
    font-size: 13px;
    line-height: 30px;
    strong {
      font-weight: 600;
    }
  }
}

/* New styles for lists in FAQ answers */
.faq-answer ul,
.faq-answer ol {
  padding-left: 20px;
  margin: 16px 0;
}

.faq-answer li {
  margin-bottom: 8px;
}

.faq-answer ul li {
  list-style-type: disc;
}

.faq-answer ol li {
  list-style-type: decimal;
}

.faq-answer strong {
  font-weight: 600;
}

@media (max-width: 1341px) {
  .faq-container {
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 35px !important;
  }
  .faq-header {
    margin-bottom: 40px;
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .faq-container {
    padding: 24px 16px;
  }

  .faq-header {
    margin-bottom: 32px;
  }

  .faq-header h1 {
    font-size: 2rem;
  }

  .faq-question {
    padding: 20px;
    font-size: 0.9375rem;
  }

  .faq-answer {
    padding: 0 20px 20px;
    font-size: 0.875rem;
  }

  /* Adjust list padding on mobile */
  .faq-answer ul,
  .faq-answer ol {
    padding-left: 12px;
  }
}
