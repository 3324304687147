.marqueServicesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: #07143f;
  margin-top: 50px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 195, 255, 0.5),
      transparent
    );
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 195, 255, 0.5),
      transparent
    );
    z-index: 1;
  }

  .marquesersWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 35px;
      color: #fff;
      white-space: nowrap;
    }
    svg {
      color: #fff;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .futuristicTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    width: 100%;
    position: relative;

    .textWrapper {
      position: relative;
      padding: 0 20px;

      &::before {
        content: "";
        position: absolute;
        top: -10px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #00c3ff;
        animation: scanLine 4s infinite;
      }
    }

    .futuristicText {
      font-size: 29px;
      color: #fff;
      white-space: nowrap;
      text-shadow: 0 0 10px rgba(0, 195, 255, 0.7);
      position: relative;
      font-weight: 600;
      letter-spacing: 1px;
      min-height: 45px;
      display: flex;
      margin: 0;

      .word {
        display: inline-flex;
      }

      .space {
        opacity: 1;
        width: 0.35em;
        display: inline-block;
      }

      .char {
        display: inline-block;
        transition: all 0.3s ease;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        will-change: transform, opacity;

        // Random color variations for some letters
        &:nth-child(3n + 1) {
          color: #fff;
        }

        &:nth-child(3n + 2) {
          color: #00c3ff;
        }

        &:nth-child(3n + 3) {
          color: #e0f7ff;
        }
      }
    }

    .glowEffect {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background: radial-gradient(
        ellipse at center,
        rgba(0, 195, 255, 0.15) 0%,
        rgba(0, 195, 255, 0) 70%
      );
      pointer-events: none;
      opacity: 0.7;
      z-index: -1;
    }
  }
}

@keyframes scanLine {
  0% {
    width: 0;
    left: 0;
  }
  40% {
    width: 100%;
    left: 0;
  }
  60% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

// Additional ambient lighting effect
@keyframes ambientGlow {
  0%,
  100% {
    box-shadow: 0 0 30px rgba(0, 195, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 50px rgba(0, 195, 255, 0.4);
  }
}

@media (max-width: 1467px) {
  .marqueServicesContainer {
    .marquesersWrap {
      h2 {
        font-size: 30px;
        color: #fff;
        white-space: nowrap;
      }
      svg {
        width: 20px;
      }
    }
  }
}

@media (max-width: 1284px) {
  .marqueServicesContainer {
    .marquesersWrap {
      h2 {
        font-size: 27px;
        color: #fff;
        white-space: nowrap;
      }
      svg {
        width: 17px;
      }
    }
  }
}

@media (max-width: 1158px) {
  .marqueServicesContainer {
    .marquesersWrap {
      h2 {
        font-size: 25px;
        color: #fff;
        white-space: nowrap;
      }
      svg {
        width: 15px;
      }
    }
  }
}

@media (max-width: 1078px) {
  .marqueServicesContainer {
    .marquesersWrap {
      h2 {
        font-size: 20px;
        color: #fff;
        white-space: nowrap;
      }
      svg {
        width: 10px;
      }
    }
  }
}
@media (max-width: 1559px) {
  .marqueServicesContainer {
    margin-top: 30px;
  }
}
