// .services-main-container {
.services-wrapper {
  max-width: 1280px;
  margin: 0px auto 0;
  display: flex;
  background-color: white;
  border-radius: 20px;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
}

.slider-section {
  flex: 1;
  max-width: 600px;
}

.swiper-container {
  position: relative;
}

.services-swiper {
  width: 100%;
  height: 100%;
}

/* Navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  // width: 30px;
  // height: 30px;
  // background-color: white;
  // border-radius: 50%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // color: #07143f;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  // cursor: pointer;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  // z-index: 10;
  // transition: all 0.3s ease;
  // padding: 5px;
  display: none;
}

.swiper-button-prev {
  left: -5px;
}

.swiper-button-next {
  right: -5px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #f8f9fa;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Pagination */
.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #07143f;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
  background-color: #2b5cff;
  width: 24px;
  border-radius: 4px;
}

/* Software Development Section */
.software-dev {
  padding: 20px;
  height: 100%;
}

.dev-header {
  display: flex;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.terminal-icon {
  width: 48px;
  height: 48px;
  border: 1.5px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: monospace;
  font-size: 20px;
}

.dev-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #07143f;
  margin: 0;
  margin-top: 6px;
}

.dev-header img {
  width: 100px;
  margin-right: 10px;
}

.software-dev p {
  color: #666666;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 32px;
  line-height: 30px;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  max-width: 600px;
}

.tech-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.tech-circle {
  width: 24px;
  height: 24px;
  border: 1.5px solid #3b82f6;
  border-radius: 50%;
}

.tech-item span {
  color: #07143f;
  font-size: 14.5px;
}

.services h2 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 24px 0;
}

.services-container {
  position: relative;
  height: calc(100% - 60px);
}

.service-pill {
  position: absolute;
  padding: 10px 40px;
  border-radius: 999px;
  font-size: 15px;
  white-space: nowrap;
  color: #1f2937;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}

/* Grid pattern overlay */
.services::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  pointer-events: none;
}

/* Hide default Swiper navigation arrows */
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.services-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 80px 20px;
  background-color: #fff;
}

// .services-wrapper {
//   max-width: 1280px;
//   display: flex;
//   align-items: flex-start;
//   gap: 32px;
//   padding: 20px;
// }

/* Left Side - Scrolling Main Services */

.main-services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  // padding: 100px 20px;
  overflow: hidden;
}

.main-service {
  width: 100%;
  // padding: 40px;
  // border-radius: 10px;
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 0; /* Hidden by default */
  // transform: translateY(50px);
  transition: all 0.5s ease-in-out;
  border: 1px solid #d5daf251;
  padding: 20px;
  border-radius: 5px;
}

/* The Active Card */
.main-service.active {
  opacity: 1 !important;
  transform: scale(1.05) !important;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.service-content {
  h2 {
    margin-bottom: 10px;
    color: #07143f;
    font-size: 22px;
  }
  p {
    font-size: 14px;
    color: #666666;
    line-height: 25px;
  }
}

.service-icon {
  width: 100px;
  margin-bottom: 5px;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 20px;
}

.tech-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.main-services {
  width: 600px;
}

/* Right Side - Fixed Services Section */
.services {
  width: calc(100% - 600px);
  background: #2b5cff;
  border-radius: 16px;
  padding: 0px;
  position: sticky;
  top: 100px;
  height: 600px;
  overflow: hidden;
  // transform: translateY(-50%);
}

.services h2 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 40px;
}

.services-container {
  position: relative;
  height: calc(100% - 60px);
}

.service-pill {
  position: absolute;
  padding: 10px 40px;
  border-radius: 999px;
  font-size: 15px;
  white-space: nowrap;
  color: #1f2937;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}

@media (max-width: 1101px) {
  .services-wrapper {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .main-services {
    width: 100%;
    order: 2; // Change the order to show PhysicsServices first
  }

  .services {
    width: 100%;
    position: relative;
    top: 0;
    height: 500px; // Slightly reduce height on smaller screens
    margin-bottom: 30px;
  }

  .main-services-container {
    width: 100%;
  }

  .main-service {
    max-width: 100%;
  }

  // Adjust the title position in PhysicsServices
  .services h2 {
    top: 20px;
  }

  .ServicesSectionMargin {
    margin-bottom: -4px !important;
  }
}

// Additional media query for even smaller screens
@media (max-width: 768px) {
  .services {
    height: 400px;
  }

  .tech-grid {
    grid-template-columns: 1fr; // Change to single column on mobile
  }

  .main-service {
    padding: 15px;
  }

  .service-content h2 {
    font-size: 20px;
  }

  .service-content p {
    font-size: 13px;
    line-height: 22px;
  }
}
