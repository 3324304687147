.hero {
  position: relative;
  min-height: calc(100vh - var(--navbar-height));
  margin-top: var(--navbar-height);
  /* background-color: #fdf4ff; */
  background: linear-gradient(to bottom, #fdf3ff, #f3f5ff);

  padding: 0 1rem;
  overflow: hidden;
  min-height: clamp(600px, 100vh - var(--navbar-height), 800px);
}

.hero-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10rem 0;
  min-height: calc(100vh - var(--navbar-height));
  min-height: clamp(600px, 100vh - var(--navbar-height), 800px);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-image {
  position: absolute;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hero-image-left {
  left: 0;
  top: 10rem;
  width: 279px;
  height: 258px;
  border-radius: 0px 20px 20px 0px;
}

.hero-image-right {
  right: 0px;
  top: 15rem;
  width: 279px;
  height: 258px;
  border-radius: 0px 20px 20px 0px;
}

.hero-image-bottom {
  left: 15rem;
  bottom: 9rem;
  width: 279px;
  height: 258px;
  border-radius: 0px 20px 20px 0px;
}

.hero-content {
  text-align: center;
  z-index: 10;
  max-width: 64rem;
}

.hero-title {
  font-size: 3.8rem;
  font-weight: 900;
  color: #07143f;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  position: relative;
}

.highlight-wrapper {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.highlight-svg {
  position: absolute;
  left: -10px;
  right: -10px;
  bottom: -10px;
  width: calc(100% + 20px);
  height: 20px;
  pointer-events: none;
}

.hero-description {
  font-size: 1.11rem;
  color: #07143f;
  margin-bottom: 2rem;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 35px;
  margin-top: 30px;
}

.hero-button {
  background-color: #2b5cff;
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-size: 1.125rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-button:hover {
  background-color: #1d4ed8;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.hero-image {
  animation: float 6s ease-in-out infinite;
}

@media (max-width: 1824px) {
  .hero-container {
    min-height: fit-content;
    height: fit-content;
  }
  .hero-image-bottom {
    bottom: 0;
  }
}
@media (max-width: 1332px) {
  .hero-title {
    font-size: 2.9rem;
  }
  .hero-description {
    font-size: 1rem;
    width: 450px;
  }
}
@media (max-width: 1257px) {
  .hero-container {
    min-height: fit-content;
    height: fit-content;
  }
  .hero-title {
    font-size: 2.8rem;
  }
  .hero-description {
    font-size: 1rem;
    width: 450px;
  }

  .hero-image-left {
    width: 290px;
    height: 236px;
  }

  .hero-image-right {
    width: 290px;
    height: 236px;
  }

  .hero-image-bottom {
    display: none;
  }
}

@media (max-width: 1172px) {
  .hero-image-left {
    width: 240px;
    height: 236px;
  }

  .hero-image-right {
    width: 240px;
    height: 236px;
  }
}

@media (max-width: 1067px) {
  .hero-image-left {
    display: none;
  }

  .hero-image-right {
    display: none;
  }

  .hero-description {
    font-size: 1.1rem;
    width: fit-content;
  }
}

@media (max-width: 992px) {
  .hero {
    margin-top: 80px;
    min-height: fit-content;
    margin-bottom: 0px;
    padding-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 40px;
  }
}

@media (max-width: 900px) {
  .hero-container {
    padding-top: 50px;
    padding-bottom: 10px;
  }
  .herobr {
    display: none;
  }
}

/* @media (max-width: 592px) {
  .hero-container {
    padding-bottom: 50px;
  }
} */
