.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.navbar-scrolled {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* filter: drop-shadow(2px -2px 6px rgba(0, 0, 0, 0.253)); */
  /* filter: drop-shadow(2px -2px 10px rgba(0, 0, 0, 0.162)); */
}

.navbar-container {
  max-width: 1280px;
  margin: 0 auto;
  /* padding: 0 1rem; */
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1280px;
  margin: 0 auto 0;
}

.navbar-logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #07143f;
}

.navbar-logo img {
  height: 25px;
  width: auto;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-link {
  text-decoration: none;
  color: #07143f;
  font-weight: 400;
  transition: color 0.3s ease;
  font-size: 14px;
  cursor: pointer !important;
}

.nav-link_active {
  color: #2b5cff;
  font-size: 16px;
  font-weight: 500;
  /* text-decoration: underline; */
}
.nav-link:hover {
  color: #2b5cff;
  cursor: pointer !important;
}

.nav-button {
  background-color: #07143f;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #1e40af;
}

@media (max-width: 1280px) {
  .navbar-container {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

@media (max-width: 1024px) {
  .navbar-links {
    gap: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .mobilenavbar-optMenuFam {
    position: fixed;
    background: #ffffff;
    width: 100%;
    z-index: 9000000000;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 70px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    filter: drop-shadow(2px -2px 10px rgba(0, 0, 0, 0.162));
    /* min-height: clamp(600px, 100vh - var(--navbar-height), 800px); */
  }
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }
}
