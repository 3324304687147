/* Main Container */
.how-it-works-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 40px;
}

/* Title Styling */
.how-it-works-title {
  font-size: 30px;
  font-weight: 400;
  // margin-bottom: 40px;
  color: #07143f;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

/* Steps Wrapper (Circular Layout) */
.steps-wrapper {
  position: relative;
  width: 800px; /* Circle Size */
  height: 800px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Individual Step */
.step {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f5ff; /* Blue */
  color: #07143f;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.7s ease-in-out, transform 0.7s ease-in-out;
  line-height: 21px;
  padding: 20px;
  box-sizing: border-box;
}

/* Dynamic Colors for Each Step */
.completed-1 {
  background-color: #007bff;
  color: #fff !important;
} /* Blue */
.completed-2 {
  background-color: #007bff;
  color: #fff !important;
} /* Green */
.completed-3 {
  background-color: #007bff;
  color: #fff !important;
} /* Yellow */
.completed-4 {
  background-color: #007bff;
  color: #fff !important;
} /* Orange */
.completed-5 {
  background-color: #007bff;
  color: #fff !important;
} /* Red */

/* Circular Positions */
.step:nth-child(1) {
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
} /* Top Center */
.step:nth-child(2) {
  top: 7%;
  left: 62%;
  transform: translate(-50%, -50%);
} /* Top Right */
.step:nth-child(3) {
  top: 45%;
  left: 92%;
  transform: translate(-50%, -50%);
} /* Bottom Right */
.step:nth-child(4) {
  top: 86%;
  left: 75%;
  transform: translate(-50%, -50%);
} /* Bottom Center */
.step:nth-child(5) {
  top: 88%;
  left: 25%;
  transform: translate(-50%, -50%);
}

.step:nth-child(6) {
  top: 47%;
  left: 9%;
  transform: translate(-50%, -50%);
}
/* Bottom Left */
/* SVG Connector Lines */
.connector {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
/* Center Circle */
.center-circle {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.step-number {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #fff;
  color: #07143f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -15px;
}

.how_it_works_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.how-it-works-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.how-it-works-header-icon img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1102px) {
  .how-it-works-container {
    margin-top: 100px;
  }
}
@media (max-width: 992px) {
  .how-it-works-container {
    display: none;
  }
}
// @media (max-width: 800px) {
//   .how-it-works-container {
//     display: none;
//   }
// }
// /* Container Styling */
// .how-it-works-container {
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: white;
//   padding: 80px 20px;
// }

// /* Title Styling */
// .how-it-works-title {
//   font-size: 2.5rem;
//   font-weight: bold;
//   margin-bottom: 40px;
//   color: #333;
// }

// /* Steps Wrapper */
// .steps-wrapper {
//   position: relative;
//   width: 100%;
//   max-width: 600px;
// }

// /* Individual Step */
// .step {
//   width: 250px;
//   height: 250px;
//   border-radius: 50%;
//   padding: 24px;
//   margin: 32px auto;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   transition: background-color 0.7s ease-in-out, transform 0.7s ease-in-out;
//   background-color: #e0e0e0; /* Default Gray */
//   color: #444;
//   font-size: 1.2rem;
//   font-weight: bold;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
// }

// /* Dynamic Colors for Each Step */
// .completed-1 {
//   background-color: #007bff;
//   color: white;
// } /* Blue */
// .completed-2 {
//   background-color: #28a745;
//   color: white;
// } /* Green */
// .completed-3 {
//   background-color: #ffc107;
//   color: black;
// } /* Yellow */
// .completed-4 {
//   background-color: #fd7e14;
//   color: white;
// } /* Orange */
// .completed-5 {
//   background-color: #dc3545;
//   color: white;
// } /* Red */

// /* Animated Connector Line */
// .connector-line {
//   position: absolute;
//   top: 0;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 4px;
//   height: 100%;
//   background-color: #d1d5db;
// }

// /* Progress Dots */
// .progress-dot {
//   position: absolute;
//   width: 12px;
//   height: 12px;
//   border-radius: 50%;
//   left: 50%;
//   transform: translateX(-50%);
//   transition: all 0.7s ease-in-out;
//   background-color: #a0a0a0;
// }

// /* Active Progress Dot */
// .progress-dot.active {
//   background-color: #007bff;
//   transform: scale(1.3);
// }
