.app-button {
  background-color: #2b5cff;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.app-button:hover {
  background-color: #0a0f2c !important;
  color: #fff !important;
}
